import React from "react";
import { Link } from "gatsby";
import { Layout } from "../../components";

const FacebookAds = ({ location: { pathname } }) => {
  return (
    <Layout
      seo={{
        title: "Facebook Ads",
        href: pathname,
        lang: "pl"
      }}
      langSwitch={{
        langKey: "en",
        langSlug: "/en/offer/facebook-ads/",
      }}
    >
      <section className="facebook_section">
        <div className="text-center">
          <h1>Facebook Ads</h1>
        </div>

        <div className="row facebook_row">
          <div className="col-md-9 m-auto">
            <p>
              Za pomocą kampanii Facebook Ads możemy realizować różne cele
              marketingowe – wzrost rozpoznawalności marki, odsyłać użytkowników
              do witryny internetowej, pozyskiwać leady, czy promować posty z
              fanpage’a. To także dodatkowe narzędzie dla e-commerce – kampania
              Facebook Ads jest doskonałym wsparciem działań marketingowych dla
              sklepów internetowych. Reklamy mogą być wyświetlane nie tylko na
              Facebooku, ale również na Instagramie czy w materiałach
              błyskawicznych. Sam Facebook udostępnia wiele przestrzeni
              reklamowych, m.in: główny feed, prawa kolumna, w odtwarzanych
              filmach wideo, w aplikacji Messenger, czy też w stories
              (relacjach).
            </p>
          </div>
        </div>
        <div className="row facebook_row facebook-img">
          <img
            src={require("../../assets/img/offer/fb-ads.jpg")}
            className="img-fluid m-auto"
            alt=""
          />
        </div>
        <div className="row">
          <div className="col-md-10 m-auto">
            <p>
              Dzięki Facebook Ads docieramy do grup użytkowników o różnych
              zainteresowaniach, np. fanów określonej marki samochodów czy też
              na podstawie dokładnych danych demograficznych i geograficznych –
              np. do kobiet w wieku 20-35 lat, zamieszkałych w promieniu 20 km
              od danego miasta, posiadających dzieci w wieku 0-3 lat. Dodatkowo
              Facebook Ads pozwala na wyświetlanie reklam użytkownikom, którzy
              odwiedzili stronę internetową, weszli w interakcję z reklamą czy
              też otworzyli formularz, ale go nie uzupełnili.
            </p>
            <p>
              Możliwości promocji Twojego biznesu na Facebooku są ogromne, a
              nasi specjaliści są największymi entuzjastami nowinek w zakresie
              metod reklamowych, które bardzo chętnie wykorzystują w swojej
              pracy.
            </p>
          </div>
        </div>
      </section>

      <section className="container-fluid section_services">
        <div className="text-center">
          <h2>Wszystkie usługi</h2>
        </div>
        <div className="row">
          <div className="col-md-2 box">
            <span>Banery reklamowe</span>
            <Link to="/oferta/banery-reklamowe/">zobacz</Link>
          </div>
          <div className="col-md-2 box">
            <span>Analityka internetowa</span>
            <Link to="/oferta/analityka-internetowa/">zobacz</Link>
          </div>
          <div className="col-md-2 box">
            <span>Social Media</span>
            <Link to="/oferta/social-media/">zobacz</Link>
          </div>
          <div className="col-md-2 box">
            <span>Linkedin Ads</span>
            <Link to="/oferta/linkedin-ads/">zobacz</Link>
          </div>
          <div className="col-md-2 box">
            <span>Google Ads</span>
            <Link to="/oferta/ads-google/">zobacz</Link>
          </div>
          <div className="col-md-2 box">
            <span>Kampanie zagraniczne</span>
            <Link to="/oferta/kampanie-zagraniczne/">zobacz</Link>
          </div>
        </div>
      </section>
    </Layout>
  );
};
export default FacebookAds;
